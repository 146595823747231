<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("monthlyplan.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-2 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("monthlyplan.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <button
                class="btn btn-success btn-add ml-auto mr-4"
                @click="onAdd"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("monthlyplanning.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('monthlyplan.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-sm-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-2">
                  <!-- <b-tab title="Nama SP" active>
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"

                        v-model="filterSP"
                      />
                    </div>
                    <hr class="m-2"/>
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="sp in dataSP"
                        :key="sp.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="sp.id"
                          v-model="filterRole"
                          @change="handleFilter"
                        />
                        <label class="form-checkbox-label ssp-14-400">
                          {{ sp.name }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterKegiatan === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab> -->
                  <b-tab title="Tanggal" active>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-today"
                        :value="filterDateTypes[0]"
                        @change="resetFilter1"
                        checked
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-today">
                        <div class="col">
                          <div class="row ssp-14">Today</div>
                          <div class="row">{{ today | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-yesterday"
                        :value="filterDateTypes[1]"
                        @change="resetFilter1"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-yesterday">
                        <div class="col">
                          <div class="row ssp-14">Yesterday</div>
                          <div class="row">{{ yesterday | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-week"
                        :value="filterDateTypes[2]"
                        @change="setFilterSelectedWeek"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-week">
                        <div class="col">
                          <div class="row ssp-14">This week</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayWeek)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayWeek | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-month"
                        :value="filterDateTypes[3]"
                        @change="setFilterSelectedMonth"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-month">
                        <div class="col">
                          <div class="row ssp-14">This month</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayMonth)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayMonth | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-custom"
                        :value="filterDateTypes[4]"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label ssp-14" for="radio-custom">
                        Custom Range
                      </label>
                    </div>
                    <b-row
                      v-if="filterDateType == filterDateTypes[4]"
                      class="mb-2"
                    >
                      <b-col cols="6" class="pr-1">
                        <span>FROM</span>
                        <input
                          type="date"
                          class="form-control float-right"
                          v-model="filterSelected1"
                        />
                      </b-col>
                      <b-col cols="6" class="pl-1">
                        <span>TO</span>
                        <input
                          type="date"
                          class="form-control"
                          v-model="filterSelected2"
                        />
                      </b-col>
                    </b-row>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 ssp-14"
                      :class="{ disabled: filterDateType === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Kegiatan">
                    <div class="filter-overflow">
                      <div
                        class="form-checkbox"
                        v-for="kegiatan in jenisKegiatanType"
                        :key="kegiatan.id"
                      >
                        <input
                          :id="kegiatan.value"
                          class="form-radio-item"
                          type="radio"
                          name="kegiatan-label"
                          :value="kegiatan.value"
                          v-model="filterKegiatan"
                        />
                        <label
                          class="form-radio-label ssp-14-400"
                          :for="kegiatan.value"
                        >
                          {{ kegiatan.label }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterKegiatan === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive>
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th
                    v-for="hd in $t('monthlyplanning.headers')"
                    :key="hd.id"
                  >
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="7" />
              </b-tbody>
              <!-- <b-tbody> -->
              <b-tbody v-else-if="this.dataPlan.length !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="plan in dataPlan"
                  :key="plan.id"
                >
                  <b-td
                    ><input
                      type="checkbox"
                      :value="plan.id"
                      v-model="planIds"
                      @click="select"
                  /></b-td>
                  <b-td>
                    <b>{{ plan.sales_promotor.name }}</b>
                  </b-td>
                  <b-td>{{ plan.territory }}</b-td>
                  <!-- <b-td style="width: 10%;">
                    <span v-if="plan.distributors !== null">{{
                      plan.distributors.name
                    }}</span>
                    <span v-else>-</span>
                  </b-td> -->
                  <b-td>
                    <span
                      v-if="plan.kegiatan === 'DP'"
                      class="badge badge-blue"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'FFD'"
                      class="badge badge-orange"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'SMF'"
                      class="badge badge-red"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'SAF'"
                      class="badge badge-brown"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'ODP'"
                      class="badge badge-yellow"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'BF'"
                      class="badge badge-gray"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'KF'"
                      class="badge badge-green"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'SE'"
                      class="badge badge-white"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'R1M'"
                      class="badge badge-purple"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                    <span
                      v-if="plan.kegiatan === 'R2M'"
                      class="badge badge-purple-2"
                      >{{ convertKegiatan(plan.kegiatan) }}</span
                    >
                  </b-td>
                  <b-td>{{ plan.tanggal | formatDate2 }}</b-td>
                  <b-td>{{ plan.lokasi }}</b-td>
                  <!-- <b-td>{{ plan.tanamans.crop }}</b-td>
                  <b-td>{{ plan.produks.brand }}</b-td> -->
                  <b-td>
                    <button
                      :id="`popover-1-${plan.id}`"
                      class="btn btn-invc p-0"
                    >
                      <div class="action-wrapper font-12">
                        <i class="ri-more-2-fill ri-lg ri-mid"></i>
                      </div>
                    </button>
                    <b-popover
                      :target="`popover-1-${plan.id}`"
                      placement="right"
                      triggers="focus"
                    >
                      <div class="action-wrapper font-12">
                        <table>
                          <tr class="pointer" @click="onDetail(plan)">
                            <td style="padding: 4px">
                              <i
                                class="ri-eye-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.detail") }}</td>
                          </tr>
                          <tr class="pointer" @click="onEdit(plan)">
                            <td style="padding: 4px">
                              <i
                                class="ri-edit-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.edit") }}</td>
                          </tr>
                          <tr class="pointer" @click="onDelete(plan.id)">
                            <td style="padding: 4px">
                              <i
                                class="ri-delete-back-2-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.delete") }}</td>
                          </tr>
                        </table>
                      </div>
                    </b-popover>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <!-- <b-tbody class="text-center"> -->
                <b-tr>
                  <b-td colspan="10">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-plan" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <div class="form-group">
            <label
              for="territory/wilayah"
              class="ssp-16 text-black"
              @click="filterTerritory"
              >{{ $t("monthlyplan.label.terr") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              v-if="isDetail && !isEdit"
              type="text"
              class="form-control"
              v-model="$v.formTambah.territory.$model"
              :disabled="true"
            />
            <input
              v-if="isEdit && !isDetail"
              type="text"
              class="form-control"
              v-model="$v.formTambah.territory.$model"
            />
            <custom-select
              v-if="!isEdit && !isDetail"
              :placeholder="$t('monthlyplan.placeholder.terr')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.territory.$error
              }"
              :allow-empty="false"
              v-model="$v.formTambah.territory.$model"
              :options="dataKab"
              label="kabupaten"
              deselectLabel=" "
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.territory.$error">
              <div v-if="!$v.formTambah.territory.required">
                {{ $t("feedback.required") }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black">
              {{ $t("monthlyplan.label.sp") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="$v.formTambah.sp.$model"
              :disabled="true"
            />
            <custom-select
              v-else
              :placeholder="$t('monthlyplan.placeholder.sp')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.sp.$error
              }"
              v-model="$v.formTambah.sp.$model"
              :options="dataSP"
              label="name"
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.sp.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >Distributor
              <!-- <span v-if="!isDetail" class="text-red">*</span> -->
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.distributor"
              :disabled="true"
            />
            <custom-select
              v-else
              :placeholder="$t('monthlyplan.placeholder.dist')"
              v-model="formTambah.distributor"
              :options="dataDistributor.map(distributor => distributor.id)"
              :custom-label="
                opt => `${dataDistributor.find(x => x.id == opt).name} 
                  (${dataDistributor.find(x => x.id == opt).owner})`
              "
            >
            </custom-select>
            <!-- <custom-select
              v-else
              :placeholder="$t('monthlyplan.placeholder.dist')"
              :class="{
                'invalid-form rounded-input': formTambah.distributor
              }"
              v-model="$v.formTambah.distributor.$model"
              :options="dataDistributor.map(distributor => distributor.id)"
              :custom-label="opt => dataDistributor.find(x => x.id == opt).name"
            >
            </custom-select> -->
            <!-- <div
              class="text-sm text-red"
              v-if="$v.formTambah.distributor.$error"
            >
              <div>Distributor tidak boleh kosong</div>
            </div> -->
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("dailyreport.label.vr") }} 1
              <!-- <span v-if="!isDetail" class="text-red">*</span> -->
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.r1"
              :disabled="true"
            />
            <custom-select
              v-else
              :placeholder="$t('monthlyplan.placeholder.ret')"
              v-model="formTambah.r1"
              :options="dataR1.map(r1 => r1.id)"
              :custom-label="
                opt =>
                  `${dataR1.find(x => x.id == opt).name} 
                  (${dataR1.find(x => x.id == opt).owner})`
              "
            >
            </custom-select>
            <!-- <custom-select
              v-else
              :placeholder="$t('monthlyplan.placeholder.ret')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.r1.$error
              }"
              v-model="$v.formTambah.r1.$model"
              :options="dataR1.map(r1 => r1.id)"
                              :custom-label="
                  opt =>
                    `${dataR1.find(x => x.id == opt).name} 
                  (${dataR1.find(x => x.id == opt).owner})`
                "
            >
            </custom-select> -->
            <!-- <div
              class="text-sm text-red"
              v-if="$v.formTambah.distributor.$error"
            >
              <div>{{ $t("dailyreport.label.vr") }} tidak boleh kosong</div>
            </div> -->
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("monthlyplan.label.activity") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="$v.formTambah.kegiatan.$model"
              :disabled="true"
            />
            <custom-select
              v-else
              :placeholder="$t('monthlyplan.placeholder.ret')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.kegiatan.$error
              }"
              v-model="$v.formTambah.kegiatan.$model"
              :options="jenisKegiatanType.map(keg => keg.value)"
              :custom-label="
                options => jenisKegiatanType.find(x => x.value == options).label
              "
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.kegiatan.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("dailyreport.label.date") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              :class="{
                'invalid-form rounded-input': $v.formTambah.tanggal.$error
              }"
              v-model="$v.formTambah.tanggal.$model"
              :disabled="isDetail"
            />
            <div class="text-sm text-red" v-if="$v.formTambah.tanggal.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="lokasi-kegiatan" class="ssp-16 text-black">
              {{ $t("monthlyplan.label.loc") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.lokasi.$error }"
              id="lokasi-kegiatan"
              cols="30"
              rows="4"
              :placeholder="$t('monthlyplan.placeholder.loc')"
              v-model="$v.formTambah.lokasi.$model"
              :disabled="isDisabled"
            ></textarea>
            <div class="text-sm text-red" v-if="$v.formTambah.lokasi.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("monthlyplan.label.crop") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="$v.formTambah.crop.$model"
              :disabled="true"
            />
            <custom-select
              v-else
              :placeholder="$t('monthlyplan.placeholder.crop')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.crop.$error
              }"
              v-model="$v.formTambah.crop.$model"
              :options="dataTanaman.map(crop => crop.id)"
              :custom-label="opt => dataTanaman.find(x => x.id == opt).crop"
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.crop.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("monthlyplan.label.prod") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="$v.formTambah.produk.$model"
              :disabled="true"
            />
            <custom-select
              v-else
              :placeholder="$t('monthlyplan.placeholder.prod')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.produk.$error
              }"
              v-model="$v.formTambah.produk.$model"
              :options="dataProduk.map(produk => produk.id)"
              :custom-label="opt => dataProduk.find(x => x.id == opt).brand"
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.produk.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <button
            v-if="!isDetail"
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
          >
            {{ $t("global.save") }}
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { Card } from "@/components/index";
import Multiselect from "vue-multiselect";

import { required } from "vuelidate/lib/validators";
import JwtService from "@/core/services/jwt.service";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import {
  Q_LIST_MPLAN,
  M_ADD_MPLAN,
  M_EDIT_MPLAN,
  M_DEL_MPLAN
} from "@/graphql/tss";
import {
  Q_LIST_CROP,
  Q_LIST_PRODUK,
  Q_LIST_USERBSC,
  Q_LIST_RETAILER,
  Q_LIST_KABUPATEN,
  Q_LIST_USERSEARCH,
  Q_LIST_DISTRIBUTOR
} from "@/graphql/queries";
import {
  RetailerType,
  JenisKegiatan,
  RoleType,
  filterDateType
} from "@/graphql/enum.type.js";
// import * as XLSX from "xlsx";
// import Axios from "axios";

export default {
  components: {
    Card,
    "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      dataPlan: [],
      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],
      provID: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterSP: "",
      filterRole: [],
      filterKegiatan: null,
      show_filter: false,
      customFilter: false,
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null,

      planIds: [],
      selected: [],
      allSelected: false,

      dataR1: [],
      dataSP: [],
      dataKab: [],
      dataProduk: [],
      dataTanaman: [],
      dataDistributor: [],

      roleOptions: [RoleType.SP, RoleType.TSS],
      retailerTypeOptions: [RetailerType.R1, RetailerType.R2],
      jenisKegiatanType: [
        JenisKegiatan.BF,
        JenisKegiatan.DP,
        JenisKegiatan.FFD,
        JenisKegiatan.KF,
        JenisKegiatan.ODP,
        JenisKegiatan.R1M,
        JenisKegiatan.R2M,
        JenisKegiatan.SAF,
        JenisKegiatan.SE,
        JenisKegiatan.SMF
      ],
      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],
      modalTitle: "",

      isEdit: false,
      isDisabled: false,
      isDetail: false,

      formTambah: {
        id: "",
        territory: "",
        sp: "",
        distributor: "",
        r1: "",
        kegiatan: "",
        tanggal: "",
        lokasi: "",
        crop: "",
        produk: ""
      }
    };
  },
  validations: {
    formTambah: {
      territory: { required },
      sp: { required },
      // distributor: { required },
      // r1: { required },
      kegiatan: { required },
      tanggal: {
        required
        // minValue: minValue(moment(String(new Date())).format('YYYY-MM-DD'))
        // minValue: minValue(new Date().toISOString())
      },
      lokasi: { required },
      crop: { required },
      produk: { required }
    }
  },
  apollo: {
    listMonthlyPlan: {
      query: () => Q_LIST_MPLAN,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          region_id: this.getRegionID(),
          user_id: [JwtService.getID()],
          role: null,
          plan_type: this.filterKegiatan,
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataPlan = data.listMonthlyPlan.monthlys;
        this.totalPages = data.listMonthlyPlan.totalPage;
        this.totalData = data.listMonthlyPlan.total;
      },
      error(e) {
        this.catchError(e);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.filterSP,
          role: this.roleOptions[0],
          country_id: [],
          region_id: [],
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [JwtService.getID()],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.dataSP = data.listUser.users;
      }
    }
  },
  computed: {},
  methods: {
    filterTerritory(data) {
      this.dataKab.forEach(val => {
        if (data === val.id) {
          return val.kabupaten;
        }
      });
    },
    handleFilter() {
      // console.log(this.filterRole);
    },
    getDay(value) {
      return moment(value).format("DD");
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    setFilterSelectedWeek() {
      this.filterSelected2 = this.lastDayWeek;
      this.customFilter = false;
      this.setPage();
    },
    setFilterSelectedMonth() {
      this.filterSelected2 = this.lastDayMonth;
      this.customFilter = false;
      this.setPage();
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.customFilter = false;
      this.filterKegiatan = null;
      this.filterRole = null;
    },
    resetFilter1() {
      this.filterSelected2 = "";
      this.customFilter = false;
      this.setPage();
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    selectAll() {
      this.planIds = [];
      if (!this.allSelected) {
        for (let i in this.dataPlan) {
          this.planIds.push(this.dataPlan[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },

    onAdd() {
      this.modalTitle = this.$t("monthlyplanning.add");
      this.isEdit = false;
      this.isDetail = false;
      this.isDisabled = false;
      this.resetForm();
      this.$bvModal.show("modal-plan");
    },
    onDetail(data) {
      this.modalTitle = this.$t("monthlyplanning.detail");
      this.isEdit = false;
      this.isDetail = true;
      this.isDisabled = true;
      this.resetForm();
      this.formTambah.territory = data.territory;
      // this.dataKab.forEach(val => {
      //   if (data.territory === val.id) {
      //     this.formTambah.territory = val.kabupaten;
      //   }
      // });
      this.formTambah.sp = data.sales_promotor.name;
      if (data.distributors !== null) {
        this.formTambah.distributor = `${data.distributors.name} (${data.distributors.owner})`;
      }
      if (data.retailers !== null) {
        this.formTambah.r1 = `${data.retailers.name} (${data.retailers.owner})`;
      }
      this.formTambah.kegiatan = data.kegiatan;
      this.formTambah.tanggal = data.tanggal;
      this.formTambah.lokasi = data.lokasi;
      this.formTambah.crop = data.tanamans.crop;
      this.formTambah.produk = data.produks.brand;

      this.$bvModal.show("modal-plan");
    },
    closeModal() {
      this.$bvModal.hide("modal-plan");
    },
    resetForm() {
      this.formTambah.territory = "";
      this.formTambah.sp = "";
      this.formTambah.distributor = null;
      this.formTambah.r1 = null;
      this.formTambah.kegiatan = "";
      this.formTambah.tanggal = "";
      this.formTambah.lokasi = "";
      this.formTambah.crop = "";
      this.formTambah.produk = "";
      this.$v.$reset();
    },
    onEdit(data) {
      this.modalTitle = this.$t("monthlyplanning.edit");
      this.isEdit = true;
      this.isDetail = false;
      this.isDisabled = false;
      this.resetForm();

      // await this.fetchR1();

      this.formTambah.id = data.id;
      this.formTambah.territory = data.territory;
      this.formTambah.sp = data.sales_promotor;
      // console.log(data.sales_promotor);
      if (data.distributors !== null) {
        this.formTambah.distributor = data.distributors.id;
      }
      // console.log(data.retailers);
      if (data.retailers !== null) {
        this.formTambah.r1 = data.retailers.id;
      }
      this.formTambah.kegiatan = data.kegiatan;
      this.formTambah.tanggal = data.tanggal;
      this.formTambah.lokasi = data.lokasi;
      this.formTambah.crop = data.tanamans.id;
      this.formTambah.produk = data.produks.id;

      this.$bvModal.show("modal-plan");
    },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        if (this.isEdit) {
          return new Promise((resolve, reject) => {
            const addPlan = this.$apollo.mutate({
              mutation: M_EDIT_MPLAN,
              variables: {
                id: this.formTambah.id,
                uid: JwtService.getID(),
                role: this.roleOptions[1],
                region_id: this.getRegionID().toString(),
                tanggal: this.formTambah.tanggal,
                kegiatan: this.formTambah.kegiatan,
                territory: this.formTambah.territory,
                sp: this.formTambah.sp.id,
                distributor: parseInt(this.formTambah.distributor),
                retailer: this.formTambah.r1,
                lokasi: this.formTambah.lokasi,
                tanaman: this.formTambah.crop,
                produk: this.formTambah.produk
              }
            });
            if (resolve) {
              resolve(addPlan);
            } else {
              reject(addPlan);
            }
          })
            .then(response => {
              this.$apollo.queries.listMonthlyPlan.refetch();
              if (response) {
                this.toastAlert(this.$t("alert.edit"));
                this.closeModal();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            });
        } else {
          return new Promise((resolve, reject) => {
            const addPlan = this.$apollo.mutate({
              mutation: M_ADD_MPLAN,
              variables: {
                uid: JwtService.getID(),
                role: this.roleOptions[1],
                region_id: this.getRegionID().toString(),
                tanggal: this.formTambah.tanggal,
                kegiatan: this.formTambah.kegiatan,
                territory: this.formTambah.territory.kabupaten,
                sp: this.formTambah.sp.id,
                distributor: this.formTambah.distributor,
                retailer: this.formTambah.r1,
                lokasi: this.formTambah.lokasi,
                tanaman: this.formTambah.crop,
                produk: this.formTambah.produk
              }
            });
            if (resolve) {
              resolve(addPlan);
            } else {
              reject(addPlan);
            }
          })
            .then(response => {
              this.$apollo.queries.listMonthlyPlan.refetch();
              if (response) {
                this.toastAlert(this.$t("alert.add"));
                this.closeModal();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            });
        }
      }
    },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          return new Promise((resolve, reject) => {
            const delLaporan = this.$apollo.mutate({
              mutation: M_DEL_MPLAN,
              variables: {
                id: id
              }
            });
            if (resolve) {
              resolve(delLaporan);
            } else {
              reject(delLaporan);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.delete"));
                this.$apollo.queries.listMonthlyPlan.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
        }
      });
    },

    async fetchKabs(data) {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_KABUPATEN,
          variables: {
            keyword: "",
            province_id: data.toString()
          }
        });
        result.data.listKabupaten.kabupatens.map(items => {
          this.dataKab.push(items);
        });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    fetchKab() {
      this.getProvinceID().map(items => {
        this.fetchKabs(items);
      });
    },
    async fetchR1() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_RETAILER,
          variables: {
            keyword: "",
            type: this.retailerTypeOptions[0],
            user_id: [JwtService.getID()],
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            province_id: this.getProvinceID(),
            kabupaten_id: this.getKabupatenID()
          }
        });
        this.dataR1 = result.data.listRetailer.retailers;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchSPSearch() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_USERBSC,
          variables: {
            page: 1,
            limit: 9999,
            keyword: this.filterSP,
            role: this.roleOptions[0],
            country_id: parseInt(this.countryID),
            region_id: parseInt(this.regionID),
            prov_id: this.getProvinceID(),
            kab_id: [],
            kec_id: [],
            kel_id: [],
            tss_id: []
          }
        });
        this.dataSP = result.data.listUser.users;
        this.formTambah.sp = "";
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchProduk() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_PRODUK,
          variables: {
            page: 1,
            limit: 9999,
            keyword: ""
          }
        });
        this.dataProduk = result.data.listProduk.produks;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchTanaman() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_CROP,
          variables: {
            page: 1,
            limit: 9999,
            keyword: ""
          }
        });
        this.dataTanaman = result.data.listCropName.crops;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchDistributor() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_DISTRIBUTOR,
          variables: {
            keyword: "",
            user_id: [JwtService.getID()],
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            province_id: [],
            kabupaten_id: []
          }
        });
        result.data.listDistributor.distributors.map(a => {
          this.dataDistributor.push({
            ...a,
            owner: a.owner ? a.owner : "-"
          });
        });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async handleExport() {
      this.isLoading = true;
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_MPLAN,
          variables: {
            page: 1,
            limit: 999999,
            keyword: "",
            region_id: [],
            user_id: [JwtService.getID()],
            role: null,
            plan_type: this.filterKegiatan,
            start_date: this.filterSelected1,
            end_date: this.filterSelected2,
            dateType: this.filterDateType
          }
        });
        const list = result.data.listMonthlyPlan.monthlys;
        import("@/plugins/export-excel").then(excel => {
          const tHeader = [
            "Sales Promotor",
            "Distributor",
            "Retailer 1",
            "Kegiatan",
            "Tanggal",
            "Lokasi",
            "Tanaman",
            "Fokus Produk"
          ];
          const filterVal = [
            "sp",
            "dist",
            "ret",
            "kegiatan",
            "tanggal",
            "lokasi",
            "tanaman",
            "produk"
          ];
          setTimeout(() => {
            list.map(a => {
              if (a.sales_promotor) {
                a.sp = a.sales_promotor.name;
              }
              if (a.distributors) {
                a.dist = a.distributors.name;
              }
              if (a.retailers) {
                a.ret = a.retailers.name;
              }
              a.kegiatan = this.convertKegiatan(a.kegiatan);
              a.tanggal = this.dateFr(a.tanggal, "DD/MM/YYYY");
              if (a.tanamans) {
                a.tanaman = a.tanamans.crop;
              }
              if (a.produks) {
                a.produk = a.produks.brand;
              }
              return a;
            });
            const data = this.formatJson(filterVal, list);
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: `monthlyplan-${this.dateFr(
                new Date(),
                "DDMMYYYYHHMMss"
              )}`,
              autoWidth: true,
              bookType: "xlsx"
            });
          }, 1500);
          this.limit = 10;
        });
      } catch (e) {
        this.errorAlert(e);
      }
      this.isLoading = false;
    },
    convertKegiatan(data) {
      let Value;
      switch (data) {
        case "DP":
          Value = "Demo Plot";
          break;
        case "FFD":
          Value = "FFD";
          break;
        case "SMF":
          Value = "Small FM";
          break;
        case "SAF":
          Value = "Safari FM";
          break;
        case "ODP":
          Value = "ODP";
          break;
        case "BF":
          Value = "Big FM";
          break;
        case "KF":
          Value = "Key FM";
          break;
        case "SE":
          Value = "Sponsorship Event";
          break;
        case "R1M":
          Value = "R1 Meeting";
          break;
        case "R2M":
          Value = "R2 Meeting";
          break;
        default:
          Value = "Wrong Activity";
          break;
      }
      return Value;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
    this.fetchR1();
    this.fetchKab();
    this.fetchProduk();
    this.fetchTanaman();
    this.fetchSPSearch();
    this.fetchDistributor();
    this.getProvinceID();
  }
};
</script>
<style></style>
